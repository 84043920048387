<template>
  <v-app id="login" style="background-color: #006a9eff">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card
              class="mx-auto"
              max-width="500"
              min-width="350"
              style="font-family: lato"
              elevation="10"
              rounded="xl"
            >
              <v-row align="center" justify="center">
                <v-col cols="12" align="center">
                  <v-img
                    class="mt-10"
                    width="250"
                    src="../assets/LOGO.png"
                  ></v-img>
                </v-col>
                <v-col cols="12" align="center" class="mt-5">
                  <v-btn
                    large
                    class="social-button mb-10"
                    style="background-color: #006a9eff; color: white"
                    rounded
                    :loading="loading"
                    @click="socialLogin()"
                  >
                    Connectez-vous avec Google
                    <v-icon class="pl-3">mdi-google</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialogConnectionRefused"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="#dd0061" dark>Accès interdit</v-toolbar>
            <v-card-text>
              <div class="text-h6 pa-7">
                Vous n'avez pas les droits nécessaires pour pouvoir accéder à
                cette application. Si vous souhaitez accéder à cette dernière,
                merci d'ouvrir un ticket sur Help SI
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Fermer</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialogSessionExpired"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="#dd0061" dark>Session expirée</v-toolbar>
            <v-card-text>
              <div class="text-h6 pa-7">
                Votre session a expiré. Veuillez vous reconnecter.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="closeDialog">Fermer</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-app>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      primary: "#006c9f",
      secondary: "#de2e78"
    };
  },
  mounted(){
    this.$store.commit('SET_LOADING_GOOGLE_SIGNIN', false)
    // if (this.userRoles.length > 0) {
    //   this.$router.replace('/candidats/ope')
    // }else{
    //   localStorage.clear() // Always clear the persist store from local storage when the login mounts
    // }
  },
  computed: {
    userRoles() {
      return this.$store.getters.currentRoles;
    },
    
    dialogConnectionRefused: {
      get: function () {
        return this.$store.getters.dialogConnectionRefused;
      },
      set: function (val) {
        //console.log(val);
        if (val === false) {
          this.$store.commit("SET_DIALOG_CONNECTION_REFUSED", val);
          this.$store.dispatch('signOutAction')
        }else{
          this.$store.commit("SET_DIALOG_CONNECTION_REFUSED", val);
        }
      },
    },
    dialogSessionExpired() {
        return this.$store.getters.dialogSessionExpired;
      },
    loading() {
      return this.$store.getters.loadingGoogleSignin;
    },
  },
  methods: {
    // Envoie une requête au serveur pour qu'on puisse s'authentifier avec google
     async socialLogin() {
      this.$store.commit('SET_LOADING_GOOGLE_SIGNIN', true)
      await this.$store.dispatch("getAuthorization")
    },
    closeDialog() {
      // Commit the mutation to close the dialog
      this.$store.commit("SET_DIALOG_SESSION_EXPIRED", false);
      localStorage.clear();
      window.location.reload()
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Lato");
a {
  text-decoration: underline;
  cursor: pointer;
}
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
  font-family: "Lato";
}
</style>